const state = {
  filters: [],
  set: false,
  view: false,
  setview: "Side",
  hidehome: false,
  hideaway: false,
  hideparam: false,
  hometeamname: "",
  awayteamname: "",
  poly: false,
  setpoly: "None",
  polys: [
    {poly: "None"},
    {poly: "Team Area"},
    {poly: "K-Regions"},
  ],
    views: [
    {
      view: "Top",
      cordL: {
        x: 5.177808383042131e-7,
        y: 1131.370849897911,
        z: 0.001131421020066395,
      },
      cordM: {
        x: -0.9733879590655801,
        y: 1126.6760400867704,
        z: 1255.1541749358078,
      },
      cordS: {
        x: 0,
        y: 2000,
        z: 0,
      },
    },
    {
      view: "Side right",
      cordL: {
        x: 417.5407933800096,
        y: 519.1997589008819,
        z: 828.306281650005,
      },
      cordM: {
        x: -0.9733879590655801,
        y: 1126.6760400867704,
        z: 1255.1541749358078,
      },
      cordS: {
         x: 817.5407933800096,
         y: 919.1997589008819,
         z: 1228.306281650005,
       },
    },
    {
      view: "Side left",
      cordL: {
        x: -417.5407933800096,
        y: 519.1997589008819,
        z: 828.306281650005,
      },
      cordM: {
        x: 0,
        y: 0,
        z: 0,
      },
      cordS: {
         x: -817.5407933800096,
         y: 919.1997589008819,
         z: 1228.306281650005,
      },
    },
    {
      view: "Side",
      cordL: {
        x: -0.44501046724866256,
        y: 576.6627369780936,
        z: 829.0264710771377,
      },
      cordM: {
        x: -1.0628411069182262,
        y: 678.0035988275106,
        z: 963.147729951472,
      },
      cordS: {
        x: -0.9733879590655801,
        y: 1126.6760400867704,
        z: 1255.1541749358078,
      },
    },
    {
      view: "Goalkeeper left",
      cordL: {
        x: -908.278813616597,
        y: 552.2936406043233,
        z: -1.1538124840391928,
      },
      cordM: {
        x: 0,
        y: 0,
        z: 0,
      },
      cordS: {
         x: -1317.5407933800096,
         y: 519.1997589008819,
         z: 0,
      },
    },
    {
      view: "Goalkeeper right",
      cordL: {
        x: 920.7913865443311,
        y: 531.1396874046791,
        z: 5.81849889809765,
      },
      cordM: {
        x: 0,
        y: 0,
        z: 0,
      },
      cordS: {
         x: 1317.5407933800096,
         y: 519.1997589008819,
         z: 0,
      },
    },
  ],
  camera: {
    // Cambio size inziale da L a S
    //  x: -0.44501046724866256,
    //  y: 576.6627369780936,
    //  z: 829.0264710771377
    x: -0.9733879590655801,
    y: 1126.6760400867704,
    z: 1255.1541749358078,
  },
  size: "S",
  card: {
    active: false,
    data: {},
  },
};

const getters = {};

const mutations = {
  filtersParameter(state, filters) {
    state.filters = filters;
  },
  set(state, set) {
    state.set = set;
  },
  view(state, view) {
    state.view = view;
  },
  setview(state, setview) {
    state.setview = setview;
  },
  poly(state, poly) {
    state.poly = poly;
  },
  setpoly(state, setpoly) {
    state.setpoly = setpoly;
  },
  setsize(state, size) {
    state.size = size;
    console.log(state.setview);
    if (state.setview == "Top") {
      state.camera = state.views[0]["cord" + size];
    } else if (state.setview == "Side left") {
      state.camera = state.views[1]["cord" + size];
    } else if (state.setview == "Side right") {
      state.camera = state.views[2]["cord" + size];
    } else if (state.setview == "Side") {
      state.camera = state.views[3]["cord" + size];
    } else if (state.setview == "Portier left") {
      state.camera = state.views[4]["cord" + size];
    } else if (state.setview == "Portier right") {
      state.camera = state.views[5]["cord" + size];
    }
  },
  setcamera(state, camera) {
    if (state.size == "L") {
      state.camera = camera.cordL;
    } else if (state.size == "M") {
      state.camera = camera.cordM;
    } else if (state.size == "S") {
      state.camera = camera.cordS;
    }
  },
  setcameraz(state, z) {
    state.camera.z = z;
  },
  hidehome(state, hide) {
    state.hidehome = hide;
  },
  hideaway(state, hide) {
    state.hideaway = hide;
  },
  hideparam(state, hideparam) {
    state.hihideparamde = hideparam;
  },
  card(state, card) {
    state.card.active = card.active;
    state.card.data = card.data;
  },
  sethometeamname(state, name) {
    state.hometeamname = name;
    console.log("sethometeamname " + state.hometeamname);
  },
  setawayteamname(state, name) {
    state.awayteamname = name;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
