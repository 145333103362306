const state = {
  players: [],
  playersAlarmsType1: [],
  playersAlarmsType2: [],
  ttlAlarmsType1: [],
  ttlAlarmsType2: [],
};

const getters = {};

const mutations = {
  players(state, players) {
    // Aggiunge attributo color se non presente
    players.map((x) => {
      if (x.color == undefined || x.color == null)
         x.color = "";
    });
    state.players = players;
  },
  playersAlarmsType1(state, playersAlarms) {
    state.playersAlarmsType1 = playersAlarms;
  },
  playersAlarmsType2(state, playersAlarms) {
    state.playersAlarmsType2 = playersAlarms;
  },
  ttlAlarmsType1(state, ttlAlarms) {
    state.ttlAlarmsType1 = ttlAlarms;
  },
  ttlAlarmsType2(state, ttlAlarms) {
    state.ttlAlarmsType2 = ttlAlarms;
  },

};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
