const state = {
 home: true,
 away: false,
 exportcsv: false,
 tableModeToggle: false, 
 tableMode: "Realtime", //realtime ==> calcoli live; progress ==> valori post cumulati
 tableModes: [
   {tableMode: "Realtime"}, //Realtime ==> calcoli live
   {tableMode: "Cumulative"}, //Cumulative ==> valori cumulati
   //{tableMode: "Historical"} //Historical ==> valori storici
 ]
};

const getters = {};

const mutations = {
 away(state, away) {
  state.away = away;
 },
 home(state, home) {
  state.home = home;
 },
 exportcsv(state, exportcsv) {
  state.exportcsv = exportcsv;
 },
 tableModeToggle(state, tableModeToggle) {
    state.tableModeToggle = tableModeToggle;
  },
 tableMode(state, t) {
    state.tableMode = t;
  },

};

const actions = {

};

export default {
 state,
 getters,
 actions,
 mutations
};
