/*
 * Defines classes used globally
 */

//XY Generic Point
export class Point {
    constructor(x, y) {
        this._x = x;
        this._y = y;
    }

    get x() {
        return this._x;
    }
    set x(x) {
        this._x = x;
    }

    get y() {
        return this._y;
    }
    set y(y) {
        this._y = y;
    }
}

//XY Generic Point With Render Info
export class DrawPoint extends Point {
    constructor(x, y, radius, color) {
        super(x, y);
        this._radius = radius;
        this._color = color;
    }

    get x() {
        return this._x;
    }
    set x(x) {
        this._x = x;
    }

    get y() {
        return this._y;
    }
    set y(y) {
        this._y = y;
    }

    get radius() {
        return this._radius;
    }
    set radius(radius) {
        this._radius = radius;
    }

    get color() {
        return this._color;
    }
    set color(color) {
        this._color = color;
    }
}

// PlayerData ==> Complete Player Info
export class PlayerData extends DrawPoint {
    constructor(x, y, radius, color, teamId, playerId, jerseyNum, role) {
        super(x, y, radius, color);
        this._teamId = teamId;
        this._playerId = playerId;
        this._jerseyNum = jerseyNum;
        this._role = role;
    }

    get teamId() {
        return this._teamIdr;
    }
    set teamId(teamId) {
        this._teamId = teamId;
    }

    get playerId() {
        return this._playerId;
    }
    set playerId(playerId) {
        this._playerId = playerId;
    }

    get jerseyNum() {
        return this._jerseyNum;
    }
    set jerseyNum(jerseyNum) {
        this._jerseyNum = jerseyNum;
    }

    get role() {
        return this._role;
    }
    set role(role) {
        this._role = role;
    }

}

// SingleRowData ==> single row from raw data in a structured Object
export class SingleRowData {
    constructor(timeInfo, timeCode, matchPeriod, periodTime, homePlayers, awayPlayers, referees, ball) {
        this._timeInfo = timeInfo;
        this._timeCode = timeCode;
        this._matchPeriod = matchPeriod;
        this._periodTime = periodTime;
        this._homePlayers = homePlayers;
        this._awayPlayers = awayPlayers;
        this._referees = referees;
        this._ball = ball;
    }

    get timeInfo() {
        return this._timeInfo;
    }
    set timeInfo(timeInfo) {
        this._timeInfo = timeInfo;
    }

    get timeCode() {
        return this._timeCode;
    }
    set timeCode(timeCode) {
        this._timeCode = timeCode;
    }

    get matchPeriod() {
        return this._matchPeriod;
    }
    set matchPeriod(matchPeriod) {
        this._matchPeriod = matchPeriod;
    }

    get periodTime() {
        return this._periodTime;
    }
    set periodTime(periodTime) {
        this._periodTime = periodTime;
    }

    get homePlayers() {
        return this._homePlayers;
    }
    set homePlayers(homePlayers) {
        this._homePlayers = homePlayers;
    }

    get awayPlayers() {
        return this._awayPlayers;
    }
    set awayPlayers(awayPlayers) {
        this._awayPlayers = awayPlayers;
    }

    get referees() {
        return this._referees;
    }
    set referees(referees) {
        this._referees = referees;
    }

    get ball() {
        return this._ball;
    }
    set ball(ball) {
        this._ball = ball;
    }

}

export class MatchConfig {

    // Parametri Calcolo Aree

    constructor(fieldWidth, fieldLength, imgLength, imgHeight, imgBorder, basePointRadius) {
        this._fieldWidth = fieldWidth;
        this._fieldLength = fieldLength;
        this._imgLength = imgLength;
        this._imgHeight = imgHeight;
        this._imgBorder = imgBorder;
        this._basePointRadius = basePointRadius;

        // Calculated Members
        this._meter2PixelRatioX = (imgLength - 2 * imgBorder) / fieldLength;
        this._meter2PixelRatioY = (imgHeight - 2 * imgBorder) / fieldWidth;
        this._midLineX = imgLength / 2; //Bordo Simmetrico
        this._leftField = [[imgBorder, imgBorder], [this._midLineX, imgBorder], [this._midLineX, imgHeight - imgBorder], [imgBorder, imgHeight - imgBorder]];
        this._rightField = [[this._midLineX, imgBorder], [imgLength - imgBorder, imgBorder], [imgLength - imgBorder, imgHeight - imgBorder], [this._midLineX, imgHeight - imgBorder]];
    }

    get meter2PixelRatioX() {
        return this._meter2PixelRatioX;
    }

    get meter2PixelRatioY() {
        return this._meter2PixelRatioY;
    }

    get leftField() {
        return this._leftField;
    }

    get rightField() {
        return this._rightField;
    }

    get imgBorder() {
        return this._imgBorder;
    }

    get imgLength() {
        return this._imgLength;
    }

    get imgHeight() {
        return this._imgHeight;
    }

    get basePointRadius() {
        return this._basePointRadius;
    }
}

export class MatchHullsPolygons {
    constructor() {
        this._currentHomePolygon;
        this._currentAwayPolygon;
    }
    get currentHomePolygon() {
        return this._currentHomePolygon;
    };
    set currentHomePolygon(currentHomePolygon) {
        return this._currentHomePolygon = currentHomePolygon;
    };

    get currentAwayPolygon() {
        return this._currentAwayPolygon;
    };
    set currentAwayPolygon(currentAwayPolygon) {
        return this._currentAwayPolygon = currentAwayPolygon;
    };


}

export class MatchHullsData {
    constructor() {
        this._currentHomePolygon;
        this._currentAwayPolygon;
        this._currentHomePolygonMt;
        this._currentAwayPolygonMt;
        this._currentTparams;
    }

    // Getters
    get currentHomePolygon() {
        return this._currentHomePolygon;
    };
    get currentAwayPolygon() {
        return this._currentAwayPolygon;
    };
    get currentHomePolygonMt() {
        return this._currentHomePolygonMt;
    };
    get currentAwayPolygonMt() {
        return this._currentAwayPolygonMt;
    };
    get currentTparams() {
        return this._currentTparams;
    };

    // Setters
    set currentHomePolygon(currentHomePolygon) {
        return this._currentHomePolygon = currentHomePolygon;
    };
    set currentAwayPolygon(currentAwayPolygon) {
        return this._currentAwayPolygon = currentAwayPolygon;
    };
    set currentHomePolygonMt(currentHomePolygonMt) {
        return this._currentHomePolygonMt = currentHomePolygonMt;
    };
    set currentAwayPolygonMt(currentAwayPolygonMt) {
        return this._currentAwayPolygonMt = currentAwayPolygonMt;
    };
    set currentTparams(currentTparams) {
        return this._currentTparams = currentTparams;
    };

}

export class MatchVoronoiData {
    constructor() {
        this._currentPoints;
        this._currentPolygons;
        this._currentPolygonsMt;
        this._currentHomeAwayFlags;
        // Tickers
        this._TicksTotal = 0;
        this._TicksPeriod1 = 0;
        this._TicksPeriod2 = 0;
        this._TicksPeriod3 = 0;
        this._TicksPeriod4 = 0;
        // Area
        this._currentHomeArea = 0;
        this._currentAwayArea = 0;
        this._sumTotalHomeArea = 0;
        this._sumTotalAwayArea = 0;
        this._sumPeriod1HomeArea = 0;
        this._sumPeriod1AwayArea = 0;
        this._sumPeriod2HomeArea = 0;
        this._sumPeriod2AwayArea = 0;
        this._sumPeriod3HomeArea = 0;
        this._sumPeriod3AwayArea = 0;
        this._sumPeriod4HomeArea = 0;
        this._sumPeriod4AwayArea = 0;
        // Dominance
        this._currentHomeDominance = 0;
        this._currentAwayDominance = 0;
        this._sumTotalHomeDominance = 0;
        this._sumTotalAwayDominance = 0;
        this._sumPeriod1HomeDominance = 0;
        this._sumPeriod1AwayDominance = 0;
        this._sumPeriod2HomeDominance = 0;
        this._sumPeriod2AwayDominance = 0;
        this._sumPeriod3HomeDominance = 0;
        this._sumPeriod3AwayDominance = 0;
        this._sumPeriod4HomeDominance = 0;
        this._sumPeriod4AwayDominance = 0;
    }

    // Getters
    get currentPoints() {
        return this._currentPoints;
    };
    get currentPolygons() {
        return this._currentPolygons;
    };
    get currentPolygonsMt() {
        return this._currentPolygonsMt;
    };
    get currentHomeAwayFlags() {
        return this._currentHomeAwayFlags;
    };
    get currentHomeArea() {
        return this._currentHomeArea;
    };
    get currentAwayArea() {
        return this._currentAwayArea;
    };
    get currentHomeDominance() {
        return this._currentHomeDominance;
    };
    get currentAwayDominance() {
        return this._currentAwayDominance;
    };

    // Setters
    set currentPoints(currentPoints) {
        return this._currentPoints = currentPoints;
    };
    set currentPolygons(currentPolygons) {
        return this._currentPolygons = currentPolygons;
    };
    set currentPolygonsMt(currentPolygonsMt) {
        return this._currentPolygonsMt = currentPolygonsMt;
    };
    set currentHomeAwayFlags(currentHomeAwayFlags) {
        return this._currentHomeAwayFlags = currentHomeAwayFlags;
    };
    set TicksTotal(TicksTotal) {
        return this._TicksTotal = TicksTotal;
    };
    set TicksPeriod1(TicksPeriod1) {
        return this._TicksPeriod1 = TicksPeriod1;
    };
    set TicksPeriod2(TicksPeriod2) {
        return this._TicksPeriod2 = TicksPeriod2;
    };
    set TicksPeriod3(TicksPeriod3) {
        return this._TicksPeriod3 = TicksPeriod3;
    };
    set TicksPeriod4(TicksPeriod4) {
        return this._TicksPeriod4 = TicksPeriod4;
    };
    set currentHomeArea(currentHomeArea) {
        return this._currentHomeArea = currentHomeArea;
    };
    set currentAwayArea(currentAwayArea) {
        return this._currentAwayArea = currentAwayArea;
    };
    set sumTotalHomeArea(sumTotalHomeArea) {
        return this._sumTotalHomeArea = sumTotalHomeArea;
    };
    set sumTotalAwayArea(sumTotalAwayArea) {
        return this._sumTotalAwayArea = sumTotalAwayArea;
    };
    set sumPeriod1HomeArea(sumPeriod1HomeArea) {
        return this._sumPeriod1HomeArea = sumPeriod1HomeArea;
    };
    set sumPeriod1AwayArea(sumPeriod1AwayArea) {
        return this._sumPeriod1AwayArea = sumPeriod1AwayArea;
    };
    set sumPeriod2HomeArea(sumPeriod2HomeArea) {
        return this._sumPeriod2HomeArea = sumPeriod2HomeArea;
    };
    set sumPeriod2AwayArea(sumPeriod2AwayArea) {
        return this._sumPeriod2AwayArea = sumPeriod2AwayArea;
    };
    set sumPeriod3HomeArea(sumPeriod3HomeArea) {
        return this._sumPeriod3HomeArea = sumPeriod3HomeArea;
    };
    set sumPeriod3AwayArea(sumPeriod3AwayArea) {
        return this._sumPeriod3AwayArea = sumPeriod3AwayArea;
    };
    set sumPeriod4HomeArea(sumPeriod4HomeArea) {
        return this._sumPeriod4HomeArea = sumPeriod4HomeArea;
    };
    set sumPeriod4AwayArea(sumPeriod4AwayArea) {
        return this._sumPeriod4AwayArea = sumPeriod4AwayArea;
    };
     set currentHomeDominance(currentHomeDominance) {
        return this._currentHomeDominance = currentHomeDominance;
    };
    set currentAwayDominance(currentAwayDominance) {
        return this._currentAwayDominance = currentAwayDominance;
    };
    set sumTotalHomeDominance(sumTotalHomeDominance) {
        return this._sumTotalHomeDominance = sumTotalHomeDominance;
    };
    set sumTotalAwayDominance(sumTotalAwayDominance) {
        return this._sumTotalAwayDominance = sumTotalAwayDominance;
    };
    set sumPeriod1HomeDominance(sumPeriod1HomeDominance) {
        return this._sumPeriod1HomeDominance = sumPeriod1HomeDominance;
    };
    set sumPeriod1AwayDominance(sumPeriod1AwayDominance) {
        return this._sumPeriod1AwayDominance = sumPeriod1AwayDominance;
    };
    set sumPeriod2HomeDominance(sumPeriod2HomeDominance) {
        return this._sumPeriod2HomeDominance = sumPeriod2HomeDominance;
    };
    set sumPeriod2AwayDominance(sumPeriod2AwayDominance) {
        return this._sumPeriod2AwayDominance = sumPeriod2AwayDominance;
    };
    set sumPeriod3HomeDominance(sumPeriod3HomeDominance) {
        return this._sumPeriod3HomeDominance = sumPeriod3HomeDominance;
    };
    set sumPeriod3AwayDominance(sumPeriod3AwayDominance) {
        return this._sumPeriod3AwayDominance = sumPeriod3AwayDominance;
    };
    set sumPeriod4HomeDominance(sumPeriod4HomeDominance) {
        return this._sumPeriod4HomeDominance = sumPeriod4HomeDominance;
    };
    set sumPeriod4AwayDominance(sumPeriod4AwayDominance) {
        return this._sumPeriod4AwayDominance = sumPeriod4AwayDominance;
    };

    // Getter With Calc
    get meanTotalHomeArea() {
        return (this._TicksTotal > 0) ? this._sumTotalHomeArea / this._TicksTotal : 0;
    };
    get meanTotalAwayArea() {
        return (this._TicksTotal > 0) ? this._sumTotalAwayArea / this._TicksTotal : 0;
    };
    get meanPeriod1HomeArea() {
        return (this._TicksPeriod1 > 0) ? this._sumPeriod1HomeArea / this._TicksPeriod1 : 0;
    };
    get meanPeriod1AwayArea() {
        return (this._TicksPeriod1 > 0) ? this._sumPeriod1AwayArea / this._TicksPeriod1 : 0;
    };
    get meanPeriod2HomeArea() {
        return (this._TicksPeriod2 > 0) ? this._sumPeriod2HomeArea / this._TicksPeriod2 : 0;
    };
    get meanPeriod2AwayArea() {
        return (this._TicksPeriod2 > 0) ? this._sumPeriod2AwayArea / this._TicksPeriod2 : 0;
    };
    get meanPeriod3HomeArea() {
        return (this._TicksPeriod3 > 0) ? this._sumPeriod3HomeArea / this._TicksPeriod3 : 0;
    };
    get meanPeriod3AwayArea() {
        return (this._TicksPeriod3 > 0) ? this._sumPeriod3AwayArea / this._TicksPeriod3 : 0;
    };
    get meanPeriod4HomeArea() {
        return (this._TicksPeriod4 > 0) ? this._sumPeriod4HomeArea / this._TicksPeriod4 : 0;
    };
    get meanPeriod4AwayArea() {
        return (this._TicksPeriod4 > 0) ? this._sumPeriod4AwayArea / this._TicksPeriod4 : 0;
    };
    get meanTotalHomeDominance() {
        return (this._TicksTotal > 0) ? this._sumTotalHomeDominance / this._TicksTotal : 0;
    };
    get meanTotalAwayDominance() {
        return (this._TicksTotal > 0) ? this._sumTotalAwayDominance / this._TicksTotal : 0;
    };
    get meanPeriod1HomeDominance() {
        return (this._TicksPeriod1 > 0) ? this._sumPeriod1HomeDominance / this._TicksPeriod1 : 0;
    };
    get meanPeriod1AwayDominance() {
        return (this._TicksPeriod1 > 0) ? this._sumPeriod1AwayDominance / this._TicksPeriod1 : 0;
    };
    get meanPeriod2HomeDominance() {
        return (this._TicksPeriod2 > 0) ? this._sumPeriod2HomeDominance / this._TicksPeriod2 : 0;
    };
    get meanPeriod2AwayDominance() {
        return (this._TicksPeriod2 > 0) ? this._sumPeriod2AwayDominance / this._TicksPeriod2 : 0;
    };
    get meanPeriod3HomeDominance() {
        return (this._TicksPeriod3 > 0) ? this._sumPeriod3HomeDominance / this._TicksPeriod3 : 0;
    };
    get meanPeriod3AwayDominance() {
        return (this._TicksPeriod3 > 0) ? this._sumPeriod3AwayDominance / this._TicksPeriod3 : 0;
    };
    get meanPeriod4HomeDominance() {
        return (this._TicksPeriod4 > 0) ? this._sumPeriod4HomeDominance / this._TicksPeriod4 : 0;
    };
    get meanPeriod4AwayDominance() {
        return (this._TicksPeriod4 > 0) ? this._sumPeriod4AwayDominance / this._TicksPeriod4 : 0;
    };
}


//MatchSnapshot ==> Embeds all the info 
export class MatchSnapshot {
    constructor(singleRowData, matchHullsData, matchVoronoiData) {
        this._singleRowData = singleRowData;
        this._matchHullsData = matchHullsData;
        this._matchVoronoiData = matchVoronoiData;    }

    get singleRowData() {
        return this._singleRowData;
    }
    set singleRowData(singleRowData) {
        this._singleRowData = singleRowData;
    }

    get matchHullsData() {
        return this._matchHullsData;
    }
    set matchHullsData(matchHullsData) {
        this._matchHullsData = matchHullsData;
    }

    get matchVoronoiData() {
        return this._matchVoronoiData;
    }
    set matchVoronoiData(matchVoronoiData) {
        this._matchVoronoiData = matchVoronoiData;
    }
}

