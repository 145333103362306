const DB_NAME = "postsdb";
const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        //console.log("Found DB ==> " + DB);
        return resolve(DB);
      }

      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.log("Error opening db", e);
        reject("Error");
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        console.log("onupgradeneeded");
        let db = e.target.result;
        db.createObjectStore("rows", { keyPath: "deltatime" });
      };
    });
  },
  async deleteRow(row) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["rows"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("rows");
      store.delete(row.deltatime);
    });
  },
  async getRows(starttime) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["rows"], "readonly");
      trans.oncomplete = () => {
        resolve(rows);
      };

      let store = trans.objectStore("rows");
      let rows = [];

      var keyRangeValue = IDBKeyRange.lowerBound(starttime);

      store.openCursor(keyRangeValue).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          rows.push(cursor.value);
          cursor.continue();
        }
      };

      store.onerror = function(event) {
        console.log(store.error);
      };
    });
  },
  async getRow(time) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let row = "";
      // db
      //   .transaction(["rows"], "readonly")
      //   .objectStore("rows")
      //   .get(time).onsuccess = function(event) {
      //   row = event.target.result.value;
      // };
      var transaction = db.transaction(["rows"]);
      var objectStore = transaction.objectStore("rows");
      var request = objectStore.get(time);
      request.onerror = function(event) {
        console.log(event);
      };
      request.onsuccess = function(event) {
        resolve(request.result.raw);
      };
    });
  },
  async saveRow(row) {
    let db = await this.getDb();

    //console.log("Row ==> " + row.deltatime + " - " + row.raw.substring(0,20));
    return new Promise((resolve) => {
      let trans = db.transaction(["rows"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("rows");
      store.put(row);
    });
  },

  async saveRows(rowsArray) {
    let db = await this.getDb();

    //console.log("Row ==> " + row.deltatime + " - " + row.raw.substring(0,20));
    return new Promise((resolve) => {
      let trans = db.transaction(["rows"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("rows");

      console.time("rows");
      let cnt = 0;
      for (const rowString of rowsArray) {
        //            rows.forEach((rowString) => {
        //console.log(cnt++ + " - " + rowString.substring(0, 20));
        if (rowString) {
          const rowParts = rowString.split(";");
          const row = {
            deltatime: parseInt(rowParts[0]),
            raw: rowString,
          };
          //if (cnt++ % 100 == 0) console.log(cnt);
          try {
            store.put(row);
          } catch (e) {
            console.log(e);
          }
        }
      }
      console.timeEnd("rows");
    });
  },

  async clearData() {
    let db = await this.getDb();

    //console.log("Row ==> " + row.deltatime + " - " + row.raw.substring(0,20));
    return new Promise((resolve) => {
      let trans = db.transaction(["rows"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("rows");
      let storeRequest = store.clear();

      storeRequest.onsuccess = function() {
        console.log("Clear Successful");
      };
    });
  },
};
