<template>
  <div id="app" class="appC">
    <router-view />
    <Connection v-if="loggedIn" />
    <Animation v-if="loggedIn" />
  </div>
</template>

<script>
import Connection from "@/components/connection/Connection.vue";
import Animation from "@/components/connection/Animation.vue";
export default {
  name: "App",
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      { name: "apple-mobile-web-app-capable", content: "yes" },
    ],
  },
  data() {
    return {
      players: [],
      liveId: 0,
      update: 1,
      socket: null,
    };
  },
  components: {
    Connection,
    Animation,
  },
  methods: {},
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  created() {
    console.log("NODE_ENV: " + process.env.NODE_ENV);
    console.log("API_URL: " + process.env.VUE_APP_API_URL);
    console.log("POSTS_SERVER: " + process.env.VUE_APP_POSTS_SERVER);
    console.log("LIVES_SERVER: " + process.env.VUE_APP_LIVES_WS_SERVER);
    console.log("CONSOLE_URL: " + process.env.VUE_APP_CONSOLE_URL);
    console.log("CONTAINER_API_URL: " + process.env.VUE_APP_CONTAINER_API_URL);
    console.log("VUE_APP_STYLE: " + process.env.VUE_APP_STYLE);
    console.log("VUE_APP_BI_LINK: " + process.env.VUE_APP_BI_LINK);
    this.$vuetify.theme.dark = true;
    // Salva Style in Vuex
    this.$store.commit("appStyle", process.env.VUE_APP_STYLE);
  },
  watch: {},
  mounted() {
    // check for active theme
    let htmlElement = document.documentElement;
      htmlElement.setAttribute("theme", this.appStyle);
  },
};
</script>

<style lang="scss" scope>
@import url('https://fonts.googleapis.com/css?family=Roboto');

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: var(--color);
  background-image: var(--background-image);
  background-color: var(--background-color);
  &_background {
    background-image: url("~@/assets/images/general/sfondo.jpg");
  }
}
* {
  box-sizing: border-box;
}
</style>
