import { CompressedTextureLoader } from "three";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Intro.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: () => import("../views/Signup.vue"),
  //   meta: {
  //     allowAnonymous: true
  //   },
  // },
  {
    path: "/selecttype",
    name: "select",
    component: () => import("../views/Selecttype.vue"),
  },
  {
    path: "/live",
    name: "live",
    component: () => import("../views/Livetype.vue"),
  },
  {
    path: "/post",
    name: "post",
    component: () => import("../views/Posttype.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/Setting.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dasboard.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/field",
    name: "field",
    component: () => import("../views/Field.vue"),
  },
  {
    path: "/table",
    name: "table",
    component: () => import("../views/Table.vue"),
  },
  {
    path: "/facetoface",
    name: "facetoface",
    component: () => import("../views/Facetoface.vue"),
  },
  {
    path: "/console",
    name: "console",
    component: () => import("../views/Console.vue"),
  },
  {
    path: "/tactic",
    name: "tactic",
    component: () => import("../views/Tactic.vue"),
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("../views/Stats.vue"),
  },
  {
    path: "/postdataconsole",
    name: "postdataconsole",
    component: () => import("../views/PostDataConsole.vue"),
  },
  {
    path: "/bi",
    name: "bi",
    component: () => import("../views/BI.vue"),
  },
  {
    path: "/historyconsole",
    name: "historyconsole",
    component: () => import("../views/HistoryConsole.vue"),
  },
  {
    path: "/containerlog/:containerName",
    name: "containerlog",
    component: () => import("../views/ContainerLog.vue"),
    props: true, // Pass route.params to props
  },
  {
    path: "/perfidx",
    name: "perfidx",
    component: () => import("../views/PerfIdx.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous) {
    // Accesso Anonimo ==> Proseguo
    next();
  } else if (!store.getters.loggedIn) {
    // Accesso Anonimo e Non loggato ==> Login
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    // Verifico Validità Token
    const userid = {
      userid: store.state.user.user.id,
    };
    store
      .dispatch("checkToken", userid)
      .then((res) => {
        if (res.status != 200) {
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          });
        } else {
          next();
        }
      })
      .catch((err) => {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
          params: { msg: "Unauthorized Access. Please login" },
        });
      });
  }
  next();
});

export default router;
