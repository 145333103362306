const state = {
  tparams: [],
};

const getters = {};

const mutations = {
  changetparams(state, tparams) {
    state.tparams = tparams;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
