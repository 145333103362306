
const state = {
    snackbars: [],
   };
   
   const getters = {};
   
   const mutations = {
    setSnackbar(state, snackbar) {
        snackbar.showing = true;
        snackbar.dark = false;
        snackbar.timeout = 1000;
        snackbar.height = 15;
        snackbar.color = snackbar.color || 'success';
        const active =  state.snackbars.filter ((x) => {x.showing})
        //console.log("snackbar len ==> " + active.length);
        if (active.length == 0)
          state.snackbars = state.snackbars.concat(snackbar);
      },
       
   };
   
   const actions = {
   
   };
   
   export default {
    state,
    getters,
    actions,
    mutations
   };
   