/*
 * Il metodo onMessage lo metto su created della pagina
 */
function getSocket(wsUrl, vm) {
  const socket = new WebSocket(wsUrl);

  socket.onopen = function (event) {
    console.log("opened connection to socket");
    vm.$store.commit("setSnackbar", {
      color: "green",
      text: "Live Data Connection ==> OK",
    });
  };

  socket.onclose = function (event) {
    console.log("closed connection from socket");
    vm.$store.commit("setSnackbar", {
      color: "red",
      text: "Live Data Connection Closed",
    });
  };

  socket.onerror = function (event) {
    console.log("error: " + event.data);
    vm.$store.commit("setSnackbar", {
      color: "red",
      text: "Live Data Connection ==> Error",
    });
  };

  return socket;
}

export default getSocket;
