const state = {
  active: false,
  historyid: null,
  history: false
};

const getters = {};

const mutations = {
  sethistory(state, history) {
    state.history = history;
  },
  sethistoryactive(state, active) {
    state.active = active;
  },
  sethistoryid(state, historyid) {
    state.historyid = historyid;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
