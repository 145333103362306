import axios from "axios";
import { CompressedTextureLoader } from "three";
const state = {
  set: false,
  player: false,
  playerId: false,
  radar: false,
  updCnt: 0,
  players: [
    {
      jerseyNum: "",
      isOppositeTeam: "",
      name: "",
      color: "#00FC77",
      heatmap: [],
    },
    {
      jerseyNum: "",
      isOppositeTeam: "",
      name: "",
      color: "#ff2858",
      heatmap: [],
    },
    {
      jerseyNum: "",
      isOppositeTeam: "",
      name: "",
      color: "#00ffff",
      heatmap: [],
    },
    {
      jerseyNum: "",
      isOppositeTeam: "",
      name: "",
      color: "#ff00ff",
      heatmap: [],
    },
  ],
  graphParameter: null,
};

const getters = {};

const mutations = {
  set(state, set) {
    state.set = set;
  },
  player(state, player) {
    state.player = player;
  },
  playerId(state, player) {
    state.playerId = player;
  },
  radar(state, radar) {
    state.radar = radar;
  },
  temporal(state, temporal) {
    state.temporal = temporal;
  },
  addPlayer(state, { player, id }) {
    if (id == 0) {
      state.players = [
        player,
        state.players[1],
        state.players[2],
        state.players[3],
      ];
      player.color = "#00FC77";
    } else if (id == 1) {
      state.players = [
        state.players[0],
        player,
        state.players[2],
        state.players[3],
      ];
      player.color = "#ff2858";
    } else if (id == 2) {
      state.players = [
        state.players[0],
        state.players[1],
        player,
        state.players[3],
      ];
      player.color = "#00ffff";
    } else if (id == 3) {
      state.players = [
        state.players[0],
        state.players[1],
        state.players[2],
        player,
      ];
      player.color = "#ff00ff";
      console.log(state.players);
    }
  },
  setGraphParameter(state, gParam) {
    state.graphParameter = gParam;
  },
  f2fGraphsUpdate(state) {
    console.log("updCnt = " + state.updCnt);
    state.updCnt++;
  },
};

const actions = {
  fetchPlayer(context, credentials) {
    const id = credentials.id;
    const number = credentials.number;
    const team = credentials.team;
    const idGame = credentials.idGame;
    const headerConfig = credentials.headerConfig;
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/heatmap?liveId=" +
          idGame +
          "&isOppositeTeam=" +
          team +
          "&jerseyNum=" +
          number,
        this.headerConfig
      )
      .then((response) => {
        const player = response.data;
        console.log(player);
        context.commit("addPlayer", { player, id });
      });
  },
  fetchPostPlayer(context, credentials) {
    const id = credentials.id;
    const number = credentials.number;
    const team = credentials.team;
    const idGame = credentials.idGame;
    axios
      .get(
        process.env.VUE_APP_POSTS_SERVER +
          "/heatmap?liveId=" +
          idGame +
          "&isOppositeTeam=" +
          team +
          "&jerseyNum=" +
          number,
        this.headerConfig
      )
      .then((response) => {
        const player = response.data;
        console.log(player);
        context.commit("addPlayer", { player, id });
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
