import * as models from "../models/models.js";

/*
 * Converts Raw Data into SingleRowData from different sources
 * Se l'origine dei dati è il post sono nell'ipotesi che il punto 
 */

export function sportVU2SingleRowData(rawData, matchConfig, isPost) {
  var singleRowData = new models.SingleRowData();

  var homePlayers = [];
  var awayPlayers = [];
  var referees = [];

  var mainArray = rawData.split(":");

  // Time infos
  var timeInfoBlock = mainArray[0].split(";");
  singleRowData.timeInfo = timeInfoBlock[0];
  var matchTimeInfo = timeInfoBlock[1].split(",");
  singleRowData.matchPeriod = matchTimeInfo[1];
  singleRowData.periodTime = matchTimeInfo[0];

  // Players and Referees
  var playersPos = mainArray[1].split(";");

  // TeamId, ObjectId,JerseyNumber, X, Y
  for (var i = 0; i < playersPos.length; i++) {
    if (playersPos[i]) {
      var posData = playersPos[i].split(",");

      var teamId = posData[0];
      var playerId = posData[1];
      var jerseyNum = posData[2];
      var playerXmt = posData[3];
      var playerYmt = posData[4];
      var playerSpeed = posData[5];

      // Convert meters to pixel
      var playerXpx =
        playerXmt * matchConfig.meter2PixelRatioX + matchConfig.imgBorder;
      var playerYpx =
        playerYmt * matchConfig.meter2PixelRatioY + matchConfig.imgBorder;

      //Se l'origine dei dati è il post sono nell'ipotesi che il punto 0,0 è il centro quindi devo centrare i dati
      if (isPost) {
        playerXpx += matchConfig.imgLength/2;
        playerYpx += matchConfig.imgHeight/2;
      }
      // TEAM ID
      // 0 � Home Team
      // 1 � Visiting Team
      // 2 � Referee
      // 3 � Home Goalkeeper
      // 4 � Visiting Goalkeeper

      var pointColor = "yellow";
      switch (teamId) {
        case "0":
          homePlayers.push(
            new models.PlayerData(
              playerXpx,
              playerYpx,
              matchConfig.basePointRadius,
              "red",
              teamId,
              playerId,
              jerseyNum,
              "",
              playerSpeed
            )
          );
          break;
        case "1":
          awayPlayers.push(
            new models.PlayerData(
              playerXpx,
              playerYpx,
              matchConfig.basePointRadius,
              "green",
              teamId,
              playerId,
              jerseyNum,
              "",
              playerSpeed
            )
          );
          break;
        case "2":
          referees.push(
            new models.PlayerData(
              playerXpx,
              playerYpx,
              matchConfig.basePointRadius,
              "black",
              teamId,
              playerId,
              jerseyNum,
              "",
              playerSpeed
            )
          );
          pointColor = "black";
          break;
        case "3":
          homePlayers.push(
            new models.PlayerData(
              playerXpx,
              playerYpx,
              matchConfig.basePointRadius,
              "SALMON",
              teamId,
              playerId,
              jerseyNum,
              "GK",
              playerSpeed
            )
          );
          break;
        case "4":
          awayPlayers.push(
            new models.PlayerData(
              playerXpx,
              playerYpx,
              matchConfig.basePointRadius,
              "GREENYELLOW",
              teamId,
              playerId,
              jerseyNum,
              "GK",
              playerSpeed
            )
          );
          break;
      }
    }
  }

  singleRowData.homePlayers = homePlayers;
  singleRowData.awayPlayers = awayPlayers;
  singleRowData.referees = referees;

  // Add Ball
  if (mainArray[2]) {
    var ballPos = mainArray[2].split(",");
    var ballXpx =
      ballPos[0] * matchConfig.meter2PixelRatioX + matchConfig.imgBorder;
    var ballYpx =
      ballPos[1] * matchConfig.meter2PixelRatioY + matchConfig.imgBorder;
      //Se l'origine dei dati è il post sono nell'ipotesi che il punto 0,0 è il centro quindi devo centrare i dati
      if (isPost) {
        ballXpx += matchConfig.imgLength/2;
        ballYpx += matchConfig.imgHeight/2;
      }

    singleRowData.ball = new models.DrawPoint(
    ballXpx,
    ballYpx,
    matchConfig.basePointRadius / 2,
    "white"
  );
}

  return singleRowData;
}
