<template>
  <div id="animation"></div>
</template>

<script>
import getSocket from "@/mixins/socket.js";
import convertTime from "@/mixins/converttime.js";
import * as models from "@/mixins/models/models.js";
import * as postData from "@/mixins/postData/rawData2SingleRowData.js";
import idb from "@/mixins/idbPosts.js";

export default {
  data() {
    return {
      matchSnapshot: {},
      socket: null,
      matchConfig: {},
      isPostRunning: false,
    };
  },
  watch: {
    sessionid(val) {
      console.log("sessionid animation: " + val);
      var vm = this;
      if (vm.socket) {
        vm.socket.close();
      }
      // Nuova versione con dati da idb invece del socket
      //this.socketCall(val, 18000);
    },
    gameid(val) {
      var vm = this;
      if (vm.socket) {
        vm.socket.close();
      }
      this.update = this.update + 1;
      if (val != null) this.socketCall(val, 14000);
    },
    inittime(val) {
      this.startAnimation(val);
    },
    commandTimer(val) {
      this.getFrame(val);
    },
    paused(status) {
      // Se metto in pausa fermo l'animazione e salvo l'ultimo valore di tempo
      if (status) {
        this.$store.commit("incrAnimationCounter");
      } else {
        // Altrimenti inizio una nuova animazione dal punto in cui l'avevo lasciata
        this.$store.commit(
          "setinittime",
          parseInt(this.$store.state.time.timermill)
        );
      }
    },
  },
  computed: {
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    gameid() {
      return this.$store.state.idgame;
    },
    postactive() {
      return this.$store.state.post.active;
    },
    inittime() {
      return this.$store.state.post.inittime;
    },
    animationCounter() {
      return this.$store.state.post.animationCounter;
    },
    paused() {
      return this.$store.state.post.paused;
    },
    commandTimer() {
      return this.$store.state.time.commandTimer;
    },
  },
  methods: {
    socketCall(idgame, basePort) {
      const remainder = idgame % 1000;
      const WS_XY_PORT = basePort + remainder;
      const wsXyURL =
        process.env.VUE_APP_LIVES_WS_SERVER + ":" + WS_XY_PORT + "/ws";
      // console.log(idgame);
      // console.log(WS_XY_PORT);

      // The view model.
      var vm = this;

      // Frequencies And Time Data
      const xyFreq = 10;
      const areasFreq = 1;
      const calcFreq = 1;
      var xyInterval = 1000 / xyFreq;
      var areasInterval = 1000 / areasFreq;
      var calcInterval = 1000 / calcFreq;

      var lastXyTime = 0;
      var lastAreasTime = 0;
      var lastCalcTime = 0;

      //connect(uri, uriCalc);
      // Listen for new data.
      vm.socket = getSocket(wsXyURL, vm);
      vm.socket.onmessage = function(event) {
        /**
         * Get the time in millisecond from the socket
         */
        var singleRowData = new models.SingleRowData();
        Object.assign(singleRowData, vm.matchSnapshot.singleRowData);
        // console.log(singleRowData.periodTime);
        /**
         * Commit the current time of the match
         */
        vm.$store.commit("changetime", convertTime(singleRowData.periodTime));
        vm.$store.commit("changetimemill", singleRowData.periodTime);

        /**
         * Commit the period of the match
         */
        vm.$store.commit("setperiod", singleRowData.matchPeriod);

        // Nell'ipotesi che la frequenza più alta sia quella dell'animazione controllo
        // il tempo intercorso dalla scrittura precedente
        const currDate = new Date();
        const currTime = currDate.getTime();

        if (currTime - lastXyTime >= xyInterval) {
          lastXyTime = currTime;

          var matchSnapshotJSONString = JSON.parse(event.data);
          var matchSnapshot = new models.MatchSnapshot();
          Object.assign(matchSnapshot, matchSnapshotJSONString);

          // Render Animation
          vm.matchSnapshot = matchSnapshot;

          vm.$store.commit("changeanimation", matchSnapshot);

          // console.log(matchSnapshot);

          if (currTime - lastAreasTime >= areasInterval) {
            lastAreasTime = currTime;
            // Rended Time Info
            var singleRowData = new models.SingleRowData();
            Object.assign(singleRowData, vm.matchSnapshot.singleRowData);
            var minutes = Math.floor(singleRowData.periodTime / 60000);
            var seconds = ((singleRowData.periodTime % 60000) / 1000).toFixed(
              0
            );
            vm.periodNumber = singleRowData.matchPeriod;
            vm.periodTime = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;

            // console.log(vm.periodNumber + " - " + vm.periodTime + " - " + vm.periodNumber);
            // Aggiorna parametri tattici
            vm.$store.commit("changeanimation", matchSnapshot);

            var matchHullsData = new models.MatchHullsData();
            Object.assign(matchHullsData, vm.matchSnapshot.matchHullsData);
            var matchVoronoiData = new models.MatchVoronoiData();
            Object.assign(matchVoronoiData, vm.matchSnapshot.matchVoronoiData);
            let tparams = matchHullsData.tparams.concat(matchVoronoiData.tparams);
            vm.$store.commit("changetparams", tparams);

            // Update Areas Values
            /*
          var matchVoronoiData = new models.MatchVoronoiData();
          Object.assign(matchVoronoiData, vm.matchSnapshot.matchVoronoiData);
          const halfFieldArea =
            (matchVoronoiData.currentHomeArea +
              matchVoronoiData.currentAwayArea) /
            2;
          vm.graphValues.dominanceHomeAct =
            matchVoronoiData.currentHomeDominance / halfFieldArea;
          vm.graphValues.dominanceHomeMean =
            matchVoronoiData.meanTotalHomeDominance / halfFieldArea;
          vm.graphValues.dominanceAwayAct =
            (matchVoronoiData.currentAwayDominance * -1) / halfFieldArea;
          vm.graphValues.dominanceAwayMean =
            (matchVoronoiData.meanTotalAwayDominance * -1) / halfFieldArea;
            */
          }
        }
      };
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getFrame(val) {
      var vm = this;
      // Match Objects
      var matchHulls = {};
      var matchVoronoi = {};
      const row = await idb.getRow(val);
      // Convert Raw Data To SingleRowData
      // Tranforms raw data into row data
      var singleRowData = postData.sportVU2SingleRowData(
        row,
        vm.matchConfig,
        this.$store.state.post.active
      );
      /**
       * Commit the period of the match
       */
      vm.$store.commit("changetime", convertTime(singleRowData.periodTime));
      vm.$store.commit("changetimemill", singleRowData.periodTime);
      vm.$store.commit("setperiod", singleRowData.matchPeriod);

      var matchSnapshot = new models.MatchSnapshot(
        singleRowData,
        matchHulls,
        matchVoronoi
      );

      // Render Animation
      vm.matchSnapshot = matchSnapshot;

      vm.$store.commit("changeanimation", matchSnapshot);
    },

    async startAnimation(val) {
      var vm = this;
      const myCounter = this.animationCounter;
      console.log("inittime animation: " + val + " - Counter: " + myCounter);

      if (val > 0) {
        // Attende la fine del precedente
        while (true) {
          if (vm.isPostRunning) await this.sleep(1000);
          else break;
        }
        vm.isPostRunning = true;
        console.log("sblocco");

        // Match Objects
        var matchHulls = {};
        var matchVoronoi = {};

        let rows = [];
        // Loop sulle righe
        rows = await idb.getRows(val);
        let lastTime = 0;
        let cnt = 0;

        console.log("# righe" + rows.length);

        for (const row of rows) {
          // Verify if myCounter is still active
          if (this.animationCounter > myCounter) {
            vm.isPostRunning = false;
            console.log("interrompi");
            break;
          }

          // Convert Raw Data To SingleRowData
          // Tranforms raw data into row data
          var singleRowData = postData.sportVU2SingleRowData(
            row.raw,
            vm.matchConfig
          );

          //var singleRowData = new models.SingleRowData();
          //Object.assign(singleRowData, JSON.parse(row.raw));
          // console.log(singleRowData.periodTime);
          /**
           * Get the time in millisecond
           */
          /**
           * Commit the current time of the match
           */
          vm.$store.commit("changetime", convertTime(singleRowData.periodTime));
          vm.$store.commit("changetimemill", singleRowData.periodTime);
          /**
           * Commit the period of the match
           */
          vm.$store.commit("setperiod", singleRowData.matchPeriod);

          const actTime = singleRowData.periodTime;
          if (lastTime == 0) {
            lastTime = actTime;
          } // Format calcData for clients

          await this.sleep(actTime - lastTime);
          lastTime = actTime;

          var matchSnapshot = new models.MatchSnapshot(
            singleRowData,
            matchHulls,
            matchVoronoi
          );

          if (cnt++ % 100 == 0) console.log(cnt + " - " + actTime);

          // Render Animation
          vm.matchSnapshot = matchSnapshot;

          vm.$store.commit("changeanimation", matchSnapshot);

          // console.log(matchSnapshot);
        }
        console.log("inittime animation Counter: " + myCounter + "end ");
      }
      vm.isPostRunning = false;
    },
  },
  mounted() {},
  created() {
    // Space data
    const FIELD_WIDTH = 75;
    const FIELD_LENGTH = 110;
    const IMAGE_LENGTH = 1111;
    const IMAGE_HEIGHT = 648;
    const IMAGE_BORDER = 0;
    const POINT_RADIUS = 10;
    const HM_STEP = 3;

    // Set Match Parameters
    this.matchConfig = new models.MatchConfig(
      FIELD_WIDTH,
      FIELD_LENGTH,
      IMAGE_LENGTH,
      IMAGE_HEIGHT,
      IMAGE_BORDER,
      POINT_RADIUS,
      HM_STEP
    );
  },
};
</script>

<style lang="scss" scope>
#Field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teams {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
  position: relative;
}
</style>
