import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from 'vue-meta';
import store from "./store";
import '@mdi/font/css/materialdesignicons.css'
import vuetify from "@/plugins/vuetify";
import ProgressBar from 'vuejs-progress-bar'
import LoadScript from "vue-plugin-load-script";
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(ProgressBar)
Vue.use(LoadScript);
Vue.use(VueFriendlyIframe);
Vue.use(VueCookies, { expires: '14400s', path: '/', domain: '', secure: 'true', sameSite: 'None' });

console.log("Using Vue Version ==> " + Vue.version);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");