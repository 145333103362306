import axios from "axios";

const state = {
  token: localStorage.getItem("access_token") || null,
  user: {
    id: localStorage.getItem("userid") || null,
    name: localStorage.getItem("username") || null,
    email: localStorage.getItem("useremail") || null,
    permissions: localStorage.getItem("userpermission") || null,
  },
};

const getters = {
  loggedIn(state) {
    return state.token != null;
  },
  getHeaderConfig(state) {
    const config = {
      headers: { Authorization: `Bearer ${state.token}` },
    };
    return config;
  },
};

const mutations = {
  retrieveToken(state, token) {
    state.token = token;
  },
  retrieveUserId(state, id) {
    state.user.id = id;
  },
  initUser(state) {
    if (state.user == null || state.user == undefined) {
      state.user = {
        id: localStorage.getItem("userid") || null,
        name: localStorage.getItem("username") || null,
        email: localStorage.getItem("useremail") || null,
        permissions: localStorage.getItem("userpermission") || null,
      };
    }
  },
  retrieveUserName(state, name) {
    state.user.name = name;
  },
  retrieveUserEmail(state, email) {
    state.user.email = email;
  },
  retrieveUserPermission(state, permissions) {
    state.user.permissions = permissions;
  },
  destroyToken(state) {
    state.token = null;
  },
  destroyUser(state) {
    state.user = null;
  },
};

const actions = {
  CreateUser(context, credentials) {
    const data = {
      username: credentials.username,
      email: credentials.email,
      password: credentials.password,
      roles: credentials.role,
    };
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/auth/signup", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retrieveToken(context, credentials) {
    console.log("Retrieve Token");
    const data = {
      password: credentials.password,
      email: credentials.email,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/auth/login", data)
        .then((response) => {
          const token = response.data.token;
          //console.log("/api/auth/login response");
          //console.log(response);
          if (response.status == 200) {
            localStorage.setItem("access_token", token);
            localStorage.setItem("userid", response.data.userid);
            localStorage.setItem("username", response.data.user.name);
            localStorage.setItem("useremail", response.data.user.email);
            localStorage.setItem("userpermission", response.data.permissions);
            context.commit("retrieveToken", token);
            context.commit("initUser");
            context.commit("retrieveUserName", response.data.name);
            context.commit("retrieveUserEmail", response.data.email);
            context.commit(
              "retrieveUserPermission",
              JSON.stringify(response.data.permissions)
            );
            context.commit("retrieveUserId", response.data.userid);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  destroySession(context, credentials) {
    const data = {
      sessionid: credentials.sessionid,
    };
    if (!context.getters.loggedin) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_POSTS_SERVER + "/session/delete", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  destroyToken(context, credentials) {
    const data = {
      token: credentials.token,
    };
    if (!context.getters.loggedin) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + "/api/auth/logout", data)
          .then((response) => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            context.commit("destroyToken");
            context.commit("destroyUser");
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            context.commit("destroyToken");
            context.commit("destroyUser");
            reject(error);
          });
      });
    }
  },
  checkToken(context, credentials) {
    console.log("CheckToken");
    const data = {
      userid: credentials.userid,
    };
    const config = context.getters.getHeaderConfig;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/auth/user", data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
