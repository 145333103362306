<template>
  <div id="connection"></div>
</template>

<script>
import getSocket from "@/mixins/socket.js";
export default {
  data() {
    return {
      players: [],
      playersAlarms: [],
      customThresolds: [],
      liveId: 0,
      update: 1,
      socket: null,
      postId: 0,
    };
  },
  components: {},
  methods: {
    socketCall(idgame, basePort) {
      // Per i calcoli live online e per i calcoli post uso i websocket
      let cntTest = 0;
      var vm = this;

      const remainder = idgame % 1000;
      const WS_CALC_PORT = basePort + remainder;
      const wsCalcURL =
        process.env.VUE_APP_LIVES_WS_SERVER + ":" + WS_CALC_PORT + "/ws";

      vm.$store.commit("playersAlarmsType1", []);
      vm.$store.commit("playersAlarmsType2", []);

      vm.socket = getSocket(wsCalcURL, vm);
      vm.socket.onmessage = function(event) {
        const period = vm.$store.state.time.period;
        const timems = vm.$store.state.time.timermill;

        const alarms = vm.$store.state.alarms;
        const showAsBattery = vm.$store.state.showAsBattery;
        const showAsCircle = vm.$store.state.showAsCircle;
        let playersAlarmsType1 = [];
        let playersAlarmsType2 = [];

        // Elaboro i giocatori e setto il colore 
          vm.players = JSON.parse(event.data);
          vm.players.forEach((player) => {
            let parameters = player.parameters;
            let flt = parameters.filter(
              (param) => !alarms.includes(param.name)
            );
            player.parameters = flt;

            // Salva la % dei 2 allarmi sul giocatore ed imposta il colore
            player.alarmsValues = [];
            player.color = "";
            player.cntCircle = 0;
            parameters.forEach((parameter, idx) => {
              if (parameter.name == showAsBattery) {
                // Aggancia soglia allarme custom
                // let pThKey =
                //   (player.isOppositeTeam ? "A" : "H") +
                //   player.jerseyNum +
                //   "|" +
                //   parameter.name;
                // let pThresold = customThresolds[pThKey];
                // if (!isFinite(pThresold)) pThresold = batteryThresold;

                player.alarmsValues[1] =
                  parseFloat(parameter.value) <= parseFloat(parameter.th)
                    ? (parseFloat(parameter.value) * 100) /
                      parseFloat(parameter.th)
                    : 100;
              } else if (parameter.name == showAsCircle) {
                // Aggancia soglia allarme custom
                // let pThKey =
                //   (player.isOppositeTeam ? "A" : "H") +
                //   player.jerseyNum +
                //   "|" +
                //   parameter.name;
                // let pThresold = customThresolds[pThKey];
                // if (!isFinite(pThresold)) pThresold = circleThresold;

                player.alarmsValues[0] =
                  parseFloat(parameter.value) <= parseFloat(parameter.th)
                    ? (parseFloat(parameter.value) * 100) /
                      parseFloat(parameter.th)
                    : 100;
                player.cntCircle = parameter.cntA;
                if (player.alarmsValues[0] < 100 && parameter.new)
                  console.log("Debug");
              }

              // Imposto il colore ed aggiungo il giocatore ad array
              let pKey =
                (player.isOppositeTeam ? "Away" : "Home") +
                "|" +
                player.jerseyNum;
              if (parameter.name == alarms[1] && parameter.up) {
                player.color = "_red";
                playersAlarmsType2.push(pKey);
                // Il giocatore rimane accesso ma solo se è new mando il messaggio
                if (parameter.new)
                  vm.$store.commit("setSnackbar", {
                    color: "red",
                    text: "Alert",
                  });
              } else if (parameter.name == alarms[0] && parameter.up) {
                player.color = "_orange";
                playersAlarmsType1.push(pKey);
                // Il giocatore rimane accesso ma solo se è new mando il messaggio
                if (parameter.new) {
                  vm.$store.commit("setSnackbar", {
                    color: "orange",
                    text: "Alert",
                  });
                }
              }
            });
          });

          vm.$store.commit("playersAlarmsType1", playersAlarmsType1);
          vm.$store.commit("playersAlarmsType2", playersAlarmsType2);
          // Aggiorno i dati tabella solo se sono in modalità Realtime
          if (vm.tableMode != "Cumulative")
            vm.$store.commit("players", vm.players);

        // controllo la forzatura di aggiornamento nel F2F
        const F2fUpdCnt = timems % 60000;
        if (F2fUpdCnt < 5000) {
          vm.$store.commit("f2fGraphsUpdate");
        }
      };
    },
  },
  watch: {
    gameid(val) {
      var vm = this;
      if (vm.socket) {
        vm.socket.close();
      }
      this.update = this.update + 1;
      if (val != null) this.socketCall(val, 15000);
    },
    sessionid(val) {
      var vm = this;
      console.log("sessionid connection: " + val);
      if (vm.socket) {
        vm.socket.close();
      }
      this.update = this.update + 1;
      // Nuova versione con API del socket
      //this.socketCall(val, 19000);
    },
  },
  computed: {
    gameid() {
      return this.$store.state.idgame;
    },
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    alarms() {
      return this.$store.state.alarms;
    },
    alarmsThresolds() {
      return this.$store.state.alarmsThresolds;
    },
    tableMode() {
      return this.$store.state.table.tableMode;
    },
  },
  // created() {
  //   // this.fetchData();
  //   var vm = this;

  //   const remainder = this.liveId % 1000;
  //   const WS_CALC_PORT = 15000 + remainder;
  //   const wsCalcURL = "ws://ksportlive.racebi.com:" + WS_CALC_PORT + "/ws";

  //   const socket = getSocket(wsCalcURL);
  //   socket.onmessage = function (event) {
  //     var matchSnapshotJSONString = JSON.parse(event.data);
  //     vm.players = matchSnapshotJSONString;
  //   };
  // },
};
</script>

<style></style>
