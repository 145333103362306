import Vue from "vue";
import Vuex from "vuex";
import field from "./modules/field";
import faceto from "./modules/faceto";
import table from "./modules/table";
import players from "./modules/players";
import user from "./modules/user";
import post from "./modules/post";
import time from "./modules/time";
import animation from "./modules/animation";
import snackbar from "./modules/snackbar";
import tactic from "./modules/tactic";
import history from "./modules/history";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idgame: null,
    game: false,
    active: false,
    offline: false,
    lastOfflineUpdateTime: 0,
    offlineUpdateActive: false,
    storeIdgame: null,
    idmatch: null,
    alarms: [],
    alarmsThresolds: [],
    isActivePlayersControl: true,
    showAsBattery: null,
    showAsCircle: null,
    batteryThresold: null,
    circleThresold: null,
    customThresolds: [],
    playvideo: false,
    hasVideo: false,
    videoUrl: null, 
    videoId: null, 
    videoSkin: null,
    periodsIntervals: [],
    appStyle: null
   },
  getters: {},
  mutations: {
    setliveactive(state, active) {
      state.active = active;
    },
    setgame(state, game) {
      state.game = game;
    },
    changeidgame(state, idgame) {
      state.idgame = idgame;
    },
    setOffline(state) {
      state.offline = true;
    },
    setOnline(state) {
      state.offline = false;
    },
    setLastOfflineUpdateTime(state, time) {
      state.lastOfflineUpdateTime = time;
    },
    setOfflineUpdateActive(state, active) {
      state.offlineUpdateActive = active;
    },
    setIdMatch(state, idMatch) {
      state.idmatch = idMatch;
    },
    storeIdgame(state) {
      state.storeIdgame = state.idgame;
    },
    restoreIdgame(state) {
      state.idgame = state.storeIdgame;
    },
    addAlarm(state, alarm) {
      state.alarms.push(alarm);
    },
    addAlarm(state, alarm) {
      state.alarms.push(alarm);
    },
    alarms(state, alarms) {
      state.alarms = alarms;
    },
    alarmsThresolds(state, alarmsThresolds) {
      state.alarmsThresolds = alarmsThresolds;
    },
    setIsActivePlayersControl(state, status) {
      state.isActivePlayersControl = status;
    },
    showAsBattery(state, kpiName) {
      state.showAsBattery = kpiName;
    },
    showAsCircle(state, kpiName) {
      state.showAsCircle = kpiName;
    },
    batteryThresold(state, t) {
      state.batteryThresold = t;
    },
    circleThresold(state, t) {
      state.circleThresold = t;
    },
    customThresolds(state, t) {
      state.customThresolds = t;
    },
    playvideo(state, t) {
      state.playvideo = t;
    },
    hasVideo(state, t) {
      state.hasVideo = t;
    },
    videoUrl(state, t) {
      state.videoUrl = t;
    },
    videoId(state, t) {
      state.videoId = t;
    },
    videoSkin(state, t) {
      state.videoSkin = t;
    },
    periodsIntervals(state, t) {
      state.periodsIntervals = t;
    },
    appStyle(state, t) {
      state.appStyle = t;
    }
  },
  actions: {},
  modules: {
    field,
    faceto,
    table,
    players,
    user,
    post,
    time,
    animation,
    snackbar,
    tactic,
    history
  },
});
