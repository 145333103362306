const state = {
 animation: null
};

const getters = {};

const mutations = {
 changeanimation(state, animation) {
  state.animation = animation
 }
};

const actions = {

};

export default {
 state,
 getters,
 actions,
 mutations
};
