const state = {
 timer: null,
 timermill: null,
 period: null,
 gametime: null,
 commandTimer: null
};

const getters = {};

const mutations = {
 changetime(state, timer) {
  state.timer = timer
 },
 changetimemill(state, timer) {
  state.timermill = timer
 },
 setperiod(state, period) {
  state.period = period
 },
 setgametime(state, time) {
  state.gametime = time;
 },
 setcommandtimer(state, time) {
    state.commandTimer = time;
   },
  
};

const actions = {

};

export default {
 state,
 getters,
 actions,
 mutations
};
