const state = {
  active: false,
  postid: null,
  post: false,
  sessionid: null,
  uploaded: false,
  listpost: [],
  starttime: 0,
  endtime: 0,
  inittime: 0,
  activeredirect: false,
  animationCounter: 0,
  paused: false,
};

const getters = {};

const mutations = {
  setpost(state, post) {
    state.post = post;
    // if (post)
    //  state.game = false;
  },
  setpostactive(state, active) {
    state.active = active;
  },
  setpostid(state, postid) {
    state.postid = postid;
  },
  setsessionid(state, sessionid) {
    state.sessionid = sessionid;
  },
  clearSession(state, sessionid) {
    state.sessionid = sessionid;
  },
  changeuploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  changelistpost(state, listpost) {
    state.listpost = listpost;
  },
  setpoststart(state, start) {
    state.starttime = start;
  },
  setpostend(state, end) {
    state.endtime = end;
  },
  activeredirect(state, active) {
    state.activeredirect = active;
  },
  setinittime(state, inittime) {
    state.inittime = inittime;
  },
  incrAnimationCounter(state) {
    state.animationCounter++;
  },
  incrAnimationCounter(state) {
    state.animationCounter++;
  },
  setpaused(state, status) {
    state.paused = status;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
